@import "../scss/vars.scss";
@import "../scss/typoghraphy.scss";
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: $inter;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  background-color: $white;
}

.container {
  width: 100%;
  max-width: 1400px;
  padding: 0 15px;
  margin: 0 auto;
}

.ReactModal__Overlay {
  opacity: 0;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: all 0.2s ease;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: all 0.2s ease;
}

.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast {
  background-color: $white;
  color: $dark;
}
.Toastify__close-button {
  color: $dark;
}

@media (max-width: 480px) {
  .Toastify__toast-container--top-center {
    right: 10px;
    top: 10px;
  }
  .Toastify__toast-container {
    left: initial;
  }
}

.html-wrapper {
  & p {
    @include text_medium_regular;

    & strong {
      color: $textblack;
      @include text_medium_bold;
    }
  }

  & ul {
    margin-left: 40px;
    & li {
      list-style: disc;
      @include text_medium_regular;
      & strong {
        color: $textblack;
        @include text_medium_bold;
      }
    }
  }

  & ol {
    margin-left: 40px;

    & li {
      list-style: decimal;
      @include text_medium_regular;
      & strong {
        color: $textblack;
        @include text_medium_bold;
      }
    }
  }

  & .ql-align-center {
    text-align: center;
  }

  & .ql-align-right {
    text-align: right;
  }

  & .ql-align-justify {
    text-align: justify;
  }

  & em {
    font-style: italic;
  }

  & .ql-indent-1 {
    text-indent: 20px;
  }

  & .ql-indent-2 {
    text-indent: 40px;
  }

  & .ql-indent-3 {
    text-indent: 60px;
  }

  & .ql-indent-4 {
    text-indent: 80px;
  }

  & .ql-indent-5 {
    text-indent: 100px;
  }

  & .ql-indent-6 {
    text-indent: 120px;
  }

  & .ql-indent-7 {
    text-indent: 140px;
  }

  & .ql-indent-8 {
    text-indent: 160px;
  }

  & .ql-size-small {
    @include text_small_regular;
  }

  & .ql-size-large {
    @include text_medium_regular;
  }

  & .ql-size-huge {
    @include text_large_regular;
  }

  & h1 {
    @include desktop_h1;

    @media (max-width: 768px) {
      @include mobile_h1;
    }
  }
  & h2 {
    @include desktop_h2;

    @media (max-width: 768px) {
      @include mobile_h2;
    }
  }

  & h3 {
    @include desktop_h3;

    @media (max-width: 768px) {
      @include mobile_h3;
    }
  }

  & h4 {
    @include desktop_h4;

    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }
  & h5 {
    @include desktop_h5;

    @media (max-width: 768px) {
      @include mobile_h5;
    }
  }

  & a {
    @include text_small_regular;
    text-decoration: underline;
    color: $textblack;
  }

  & img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    object-fit: cover;

    @media (max-width: 768px) {
      height: auto;
      border-radius: 20px;
    }
  }

  & .ql-video {
    width: 100%;
    height: 400px;

    @media (max-width: 768px) {
      height: 180px;
    }
  }
}

.sliderWrapper {
  :global(.swiper-pagination-bullet-active) {
    background-color: #8c42e3;
  }
}

.ql-container {
  overflow: hidden;
}

.ql-error {
  .ql-toolbar,
  .ql-container {
    border-color: red;
  }
}
