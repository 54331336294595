@import "scss/main.scss";

.reportNotif {
  @extend %alignCenter;
  background-color: $grayF4;
  padding: 12px 16px;
  border-radius: 8px;
  svg {
    margin-right: 10px;
  }
  p {
    @extend %textNormal14;
    color: $dark;
  }
  & > [class*="tag"] {
    margin-left: 16px;
  }
}
