@import "../../../scss/main.scss";

.wrapper {
  max-width: 1440px;

  & .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
  }

  & .header {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    & .infoBlock {
      width: 50%;
      border-bottom: 1px solid $grayE4;

      & .titleBlock {
        display: flex;

        & .titleBlockLabel {
          flex: 1;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #808191;
        }

        & .titleBlockLabelCook {
          flex: 0.6;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #808191;
          margin-left: 30px;
        }
      }

      & .titleInputBlock {
        display: flex;

        margin-top: 15px;
        & .titleInputBlockTitle {
          flex: 1;
        }

        & .titleInputBlockCook {
          margin-left: 30px;
          display: flex;
          flex: 0.6;

          & .hoursInput {
            width: 50%;
          }

          & .minutesInput {
            width: 60%;
            margin-left: 10px;
          }
        }
      }

      & .ingredientsBlock {
        margin-top: 20px;
        position: relative;
        & .title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
        }

        & .titleBlock {
          display: flex;
          margin-top: 20px;

          & .ingredientTitle {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #808191;
            flex: 0.7;
          }

          & .amountTitle {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            flex: 0.2;
            color: #808191;
          }

          & .unitTitle {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            flex: 0.3;
            color: #808191;
          }
        }

        & .ingredientsWrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 10px;

          & .indedientsItemBlock {
            display: flex;
            gap: 10px;

            & .ingredient {
              flex: 0.7;
            }

            & .unitContainer {
              display: flex;
              flex-direction: column;
              gap: 10px;
              flex: 0.6;
              & .unitWrapper {
                display: flex;
                gap: 10px;
                flex-direction: row;
                & .amount {
                  flex: 0.5;
                }

                & .unit {
                  flex: 0.8;
                }
              }
            }

            & .deleteBtn {
              width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
        & .addIngredient {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px dashed #8c42e3;
          border-radius: 20px;
          width: 168px;
          height: 56px;
          margin-top: 10px;
          cursor: pointer;
          & span {
            font-family: $inter-font;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            margin-left: 10px;
            color: $primary;
          }
        }
        & .addUnit {
          margin-left: auto;
          margin-right: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px dashed #8c42e3;
          border-radius: 16px;
          width: 110px;
          height: 42px;
          margin-top: 10px;
          cursor: pointer;
          & span {
            font-family: $inter-font;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            margin-left: 5px;
            color: $primary;
          }
        }
      }
    }
    & .imageBlock {
      width: 47%;

      & .recipePhoto {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        height: 332px;
        width: 100%;
        background-color: $grayE4;
        object-fit: cover;
        background-size: cover;
        margin-bottom: 20px;
      }

      & .recipePhotoError {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        height: 332px;
        width: 100%;
        background-color: $grayE4;
        object-fit: cover;
        background-size: cover;
        margin-bottom: 20px;
        border: 2px solid $red;
      }
      & .flex {
        display: flex;
        flex-direction: row;
      }

      & .recipeUploadImgWrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        width: 166px;
        height: 40px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;

        & .recipeUploadImgBtn {
          display: none;
        }
      }

      & .recipeDeleteImgBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grayE4;
        width: 166px;
        height: 40px;
        border-radius: 8px;
        color: #000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }

  & .preparationBlock {
    width: 50%;
    padding-bottom: 20px;
    border-bottom: 1px solid $grayE4;
    & .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  & .tagsBlock {
    width: 50%;
    padding-bottom: 30px;
    border-bottom: 1px solid $grayE4;

    & .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    & .tagsContainerError {
      border: 1px solid $red;
    }

    & .tagsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 20px;
      background: #fafafa;
      border-radius: 8px;
      padding: 15px;

      & .tagsLoader {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & .footer {
    border-top: 1px solid $grayE4;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    gap: 10px;

    & .cancelBtn {
      background: $grayE4;
      border: none;
      width: 166px;
    }

    & .saveBtn {
      width: 166px;
    }

    & .previewBtn {
      width: 166px;
      background: $green;
    }

    & .saveOrCreateBtn {
      width: 320px;
      margin-left: auto;

      & .arrow {
        color: #fff;
        margin-left: 20px;
        margin-right: -20px;
        width: 10px;
        height: 10px;
      }
    }
  }
}
