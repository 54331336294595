@import '../../scss/main.scss';

.ingredientsWrapper {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  margin-top: 10px;

  .indedientsItemBlock {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    & .ingredientContainer {
      flex: 0.7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      & .ingredient {
        // flex: 1;
      }
    }

    & .unitContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 0.6;
      & .unitWrapper {
        display: flex;
        gap: 10px;
        flex-direction: row;
        & .amount {
          flex: 0.5;
        }

        & .unit {
          flex: 0.8;
        }
      }
    }

    & .deleteBtn {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.addUnit {
  margin-left: auto;
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #8c42e3;
  border-radius: 16px;
  width: 110px;
  height: 42px;
  margin-top: 10px;
  cursor: pointer;
  & span {
    font-family: $inter-font;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 5px;
    color: $primary;
  }
}
