@import "../../../scss/main.scss";

.fastingIcon {
  width: 34px;
  height: 34px;
}

.contentBox {
  width: 407px;

  @media (max-width: 768px) {
    width: 100%;
  }

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 28px;

    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 15px;
      background: #f0512226;
    }

    & h5 {
      @include desktop_h5;
    }
  }

  & .sliderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;

    & .sliderValueWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      margin-bottom: 28px;

      & .inputWrapper {
        width: 110px;
      }
      & .btnContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        & .typeBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          @include desktop_h5;
          background-color: $grayF8;
          border-radius: 10px;
          padding: 11px 10px;
          cursor: pointer;
        }

        & .typeBtnActive {
          background: $orange;
          color: $white;
        }
      }
    }

    & span {
      @include desktop_h2;
      line-height: 38px;
    }

    & .sliderOptionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 14px;
      & .sliderOption {
        @include text_x_small_regular;
        color: $grayAf;
      }
    }
  }
}
