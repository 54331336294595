@import '../../../../scss/main.scss';
.wrapper {
  max-width: 1440px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-family: $inter;
    font-size: 48px;
    line-height: 78px;
    font-weight: 600;
  }
}

.selectWrapper {
  margin-top: 30px;
}

.boostCampsList {
  margin-top: 30px;
}

.boostCampsListDroppable {
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.paginationWrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
}
