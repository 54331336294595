@import "../../../scss/main.scss";

.wrapper {
  max-width: 1440px;
  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
  & .input {
    margin-bottom: 16px;
  }
  & h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 30px;
  }

  & .headerInputWrapper {
    width: 560px;
  }

  & .shortDescription {
    height: 100px;
  }

  & .divider {
    width: "100%";
    height: 1px;
    background: $grayE4;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  & .categoryBlock {
    position: relative;
    & .deleteCategoryBtn {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      & span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        font-family: $inter-font;
        color: #afafaf;
        margin-left: 10px;
      }
    }
    & .inputWrapper {
      width: 560px;
      display: flex;
      flex-direction: column;
    }
  }

  & .addIngredientHeader {
    display: flex;
    align-items: center;
    &Title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #808191;
    }

    & .deleteIngredientBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }
  }
  & .ingredientsWrapper {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    & .nameInput {
      flex: 1;
    }

    & .amountInput {
      flex: 0.45;
    }
    & .unitInput {
      flex: 0.5;
    }

    & .unitContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
      & .unitWrapper {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;

        & .amount {
          flex: 0.5;
        }

        & .unit {
          flex: 0.8;
        }
      }
    }

    & .deleteIngredientBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 56px;
      cursor: pointer;
    }
  }
  & .addIngredientWrapper {
    position: relative;
    margin-top: 15px;
    & .addIngredientBtn {
      padding: 18px 16px;
      border: 2px dashed #8c42e3;
      border-radius: 16px;
      display: flex;
      align-items: center;
      width: 170px;
      cursor: pointer;
      & span {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $primary;
        margin-left: 8px;
      }
    }
  }
  & .addCategoryBtn {
    padding: 18px 16px;
    border: 2px dashed #8c42e3;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: 560px;
    justify-content: center;
    cursor: pointer;

    & span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $primary;
      margin-left: 8px;
    }
  }

  & .footer {
    display: flex;
    align-items: center;
    padding: 30px 0px;
    border-top: 1px solid $grayE4;

    & .cancelBtn {
      background: $grayE4;
      color: #000;
      width: 166px;
    }

    & .saveBtn {
      width: 166px;
      margin-left: 10px;
    }
    & .createBtn {
      margin-left: auto;
      width: 320px;
    }
  }
}
.addUnit {
  margin-left: auto;
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #8c42e3;
  border-radius: 16px;
  width: 110px;
  height: 42px;
  margin-top: 10px;
  cursor: pointer;
  & span {
    font-family: $inter-font;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 5px;
    color: $primary;
  }
}
