@import "scss/main.scss";

.button {
  @extend %button;
  min-width: 166px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  height: 56px;
  background-color: $blue;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @extend %textMed16;
  color: $white;
  transition: all 0.2s ease;
  &:hover {
    background-color: darken($blue, 5%);
  }
  &.disabled {
    color: $dis;
    background-color: $grayE9;
  }
  &.emptyStyle {
    background-color: $white;
    color: $dark;
    border: 2px solid $grayD5;
    &:hover {
      border-color: $blue;
    }
  }
  &.darkStyle {
    background-color: $dark;
  }
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
