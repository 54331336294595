@import "../../scss/main.scss";

.wrapper {
  display: grid;
  align-items: center;
  grid-template-columns:
    minmax(140px, 1fr) minmax(170px, 1fr) minmax(180px, 1fr)
    minmax(100px, 150px) 25px;
  overflow-wrap: normal;
  column-gap: 25px;
  border-bottom: 1px solid $grayE4;
  position: relative;
  transition: all linear 0.2s;
  height: 64px;
  cursor: pointer;

  &:hover {
    background: rgba(250, 250, 250, 1);
  }

  & div {
    overflow-wrap: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: $inter-font;
    color: $textdark;
  }

  & .user {
    display: flex;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      font-family: $inter-font;
      color: $textblack;
    }

    & .avatar {
      display: block;
      width: 44px;
      height: 44px;
      min-width: 44px;
      border-radius: 80px;
      overflow: hidden;
      margin-right: 15px;
      background-color: $grayE4;
      object-fit: cover;
    }
  }

  & .arrow {
    cursor: pointer;
    color: #141415;

    // &:hover {
    //   opacity: 0.9;
    // }
    // &:active {
    //   opacity: 0.6;
    // }
  }
}
