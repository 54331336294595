@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 50px;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .createMealBtnWrapper {
      width: 300px;
    }
    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
    }
  }
  & .searchContainer {
    display: flex;
    align-items: center;
    gap: 50px;

    position: relative;

    & .searchInputWrapper {
      display: flex;
      align-items: center;
      background: #e4e4e455;
      padding: 0px 18px;
      border-radius: 16px;
      flex: 1;
      & .searchInput {
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        height: 56px;
        margin-left: 16px;
      }
    }
  }

  & .listHeader {
    display: grid;
    align-items: center;
    margin-top: 15px;
    padding: 15px 0px;

    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    font-family: $inter-font;
    color: #b2b3bd;

    grid-template-columns:
      minmax(140px, 1fr) minmax(170px, 1fr) minmax(180px, 1fr)
      minmax(100px, 150px) 25px;
    overflow-wrap: normal;
    column-gap: 25px;
  }

  & .list {
    & .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }
  }

  & .paginationContainer {
    margin-top: 30px;
    display: flex;
    gap: 8px;

    & .nextBtn {
      cursor: pointer;
      background-color: $black;
      padding: 14px 30px;
      border-radius: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      font-family: $inter-font;
    }
  }
}

.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
}
.modal {
  & .modalInner {
    width: 450px;
    // height: 374px;
    background: $white;
    border-radius: 24px;
    padding: 32px;
    position: relative;

    & .name {
      margin-bottom: 10px;
    }
    & .lastName {
      margin-bottom: 22px;
    }

    & .loader {
      display: flex;
      width: 100%;
      height: 80%;
      align-items: center;
      justify-content: center;

      &Mini {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    & .closeBtn {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    h4 {
      @extend %textNormal14;
      color: $placeholder;
      margin-bottom: 6px;
    }

    .boostcampsList {
      display: flex;
      flex-direction: column;
      margin-bottom: 22px;
      row-gap: 11px;
    }
    .boostcampsItem {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        font-family: $inter-font;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #808191;
      }

      &Active {
        span {
          color: #000;
        }
      }
    }
  }
}
