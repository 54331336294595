@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 50px;
  & .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-left: 10px;
    }

    & .back {
      cursor: pointer;
      display: flex;
    }
  }

  & .body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  & .user {
    display: flex;
    align-items: center;
    padding: 30px;
    border-radius: 30px;
    background-color: $white;
    box-shadow: 0px 27px 72px 0px #1b1b1d0f;

    &Container {
      flex: 1;
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
    &Avatar {
      display: block;
      width: 100px;
      height: 100px;
      min-width: 100px;
      border-radius: 200px;
      overflow: hidden;
      background-color: $grayE4;
      object-fit: cover;
    }
    &Info {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      font-family: $inter-font;
    }
    &Name {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $textdark;
    }
    &Email,
    &Date {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray3A;
    }
    &Stat {
      display: flex;
      column-gap: 30px;

      &Info {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        max-width: 100px;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray91;
        font-family: $inter-font;

        span {
          font-weight: 500;
          font-size: 28px;
          line-height: 28px;
          color: $gray3A;
        }
      }
      &Divider {
        width: 1px;
        flex: 1;
        background-color: $grayE4;
      }
      &Vip {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray91;
        font-family: $inter-font;

        &__btn {
          height: 32px;
          padding: 0 13px;
          border-radius: 10px;
        }
      }
    }
  }

  & .content {
    display: grid;
    column-gap: 20px;
    align-items: flex-start;
    grid-template-columns: 270px 1fr;

    &Left {
      padding: 30px;
      border-radius: 30px;
      background-color: $white;
      box-shadow: 0px 27px 72px 0px #1b1b1d0f;
    }

    &Boost {
      display: flex;
      flex-direction: column;
      padding: 30px;
      border-radius: 30px;
      background-color: $white;
      box-shadow: 0px 27px 72px 0px #1b1b1d0f;
      row-gap: 30px;

      &Current {
      }
      &Graph {
      }
      &Divider {
        width: 100%;
        height: 1px;
        background-color: $grayE4;
      }
    }
  }

  & .empty {
    min-height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: $textdark;
    }
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.loaderBoostcampWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
}
