@import "../../scss/main.scss";

.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
  overflow-y: auto;
}

.modal {
  & .modalInner {
    width: 1126px;
    border-radius: 24px;
    background: #fff;
    padding: 32px;

    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    & .searchInputWrapper {
      display: flex;
      align-items: center;
      background: #e4e4e455;
      padding: 0px 18px;
      border-radius: 16px;
      margin-top: 22px;

      & .searchInput {
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        height: 56px;
        margin-left: 16px;
      }
    }

    & .contentContainer {
      display: flex;
      flex-direction: row;
      margin-top: 22px;
      gap: 30px;

      & .filterContainer {
        & .filterWrapper {
          width: 199px;
          border-radius: 25px;

          & .loaderWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 320px;
          }

          & .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
          }

          & .selectAllItem {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 15px 0;
            border-bottom: 1px solid $grayE4;
            & span {
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              font-family: $inter-font;
            }

            & .selectedAllCheckbox {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              border-radius: 4px;
              border: 2px solid #e4e4e4;
              cursor: pointer;
            }

            & .selecetAllCheckboxActive {
              width: 16px;
              height: 16px;
              border-radius: 4px;
              border: 5px solid $primary;
            }

            & .selectedAllCheckboxFullActive {
              width: 16px;
              height: 16px;
              border-radius: 4px;
              background: $primary;
            }
          }

          & .tagsList {
            overflow-y: scroll;
            max-height: 310px;
            border-bottom: 1px solid $grayE4;
            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-track {
              background: none;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 100px;
              background: $grayE4;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: $grayE4;
            }
            & .selectItem {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 13px 0;
              & span {
                font-family: $inter-font;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                color: #808191;
              }
            }

            & .selectItemActive {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 13px 0;
              & span {
                font-family: $inter-font;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                color: #000;
              }
            }
          }

          & .showRecipeBtn {
            height: 44px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $primary;
            margin-top: 15px;
            cursor: pointer;

            & span {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              font-family: $inter-font;
              color: #fff;
            }
          }

          & .resetBtn {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #808191;
            margin-top: 15px;
            cursor: pointer;
            text-align: center;
            font-family: $inter-font;
          }
        }
      }
      & .recipesContainer {
        flex: 1;
        height: 480px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: $grayE4;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: $grayE4;
        }
        & .loaderWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 450px;
        }
      }
    }
  }
}
