@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  padding: 30px;
  height: 145px;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  position: relative;
  // margin-bottom: 20px;

  // &:hover {
  //   cursor: pointer;
  //   background-color: rgba(0, 0, 0, 0.02);
  // }
}

.photo {
  width: 130px;
  height: 84px;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e4;
  object-fit: cover;
}

.photoLoader {
  position: absolute;
  display: flex;
  width: 130px;
  height: 84px;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
}

.titleWrapper {
  flex: 1;
  margin-right: 82px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $gray91;
  margin-top: 5px;
}

.filterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
  }
}
