@import "../../scss/main";

.wrapper {
  background: $white;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 407px;
  cursor: pointer;
  position: relative;
  border: 4px solid transparent;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
    border: 2px solid transparent;
  }
}

.activeCompletedWrapper {
  background: $white;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 407px;
  cursor: pointer;
  position: relative;
  border: 4px solid $primary;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
    border: 2px solid $primary;
  }
}

.activeWrapper {
  background: $white;
  border-radius: 20px;
  padding: 20px;

  display: flex;
  flex-direction: row;
  width: 407px;
  cursor: pointer;
  border: 4px solid $primary;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
    border: 2px solid $primary;
  }
}

.blurWrapper {
  background: $white;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 407px;
  cursor: pointer;
  position: relative;
  border: 4px solid transparent;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
    border: 2px solid transparent;
  }
}

.preview {
  width: 90px;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 60px;
    height: 53px;
    border-radius: 10px;
  }
}

.completeDayWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 80px;
  background: #60c055b3;
  position: absolute;
  border-radius: 20px;
  z-index: 99;
  @media (max-width: 768px) {
    width: 60px;
    height: 53px;
    border-radius: 10px;
  }
  & svg {
    color: $white;
  }
}

.imgWrapper {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  transform: translateZ(0);
}

.blur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  transform: translateZ(0);
}

.contentContainer {
  margin-left: 20px;

  & h5 {
    @include desktop_h5;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width: 768px) {
      @include mobile_h5;
    }
  }

  & p {
    @include text_small_regular;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $textdark;
  }
}

.closeDayWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 80px;
  position: absolute;
  border-radius: 20px;
  z-index: 999;
  @media (max-width: 768px) {
    width: 60px;
    height: 53px;
    border-radius: 10px;
  }
  & svg {
    color: $white;
  }
}
