@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 16px 0px;
  border-top: 1px solid #e4e4e4;

  & .title {
    @include  desktop_h5;
    @media (max-width: 768px){
      @include mobile_h5;
    }
  }
}

.wrapper:last-child {
  border-bottom: 1px solid #e4e4e4;
}

.wrapperActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 16px 0px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.answer {
  // @include text_medium_regular;
  padding: 15px 0;
}
