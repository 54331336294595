@import "scss/main.scss";

.statistic {
  min-width: 144px;
  height: 100%;
  @extend %flexColumn;
  justify-content: center;
  padding: 24px;
  &:not(:last-child) {
    border-right: 1px solid $grayD5;
  }
  &__label {
    @extend %alignCenter;
    margin-bottom: 2px;
    h4 {
      @extend %textNormal14;
      color: $gray80;
      margin-left: 8px;
    }
  }
  &__color {
    width: 16px;
    height: 16px;
    background-color: $green;
    border-radius: 6px;
  }
  h2 {
    @extend %textBold32;
    color: $dark;
  }
  &__line {
    width: 100%;
    height: 2px;
    background-color: $grayE4;
    border-radius: 1px;
    margin-top: 20px;
  }
}
