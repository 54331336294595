@import 'scss/main.scss';

.profile {
  @extend %alignCenterSpace;
  margin: 32px 0 24px;
  padding-right: 36px;
  &__row {
    @extend %alignCenter;
  }
  &__avatar {
    width: 56px;
    height: 56px;
    // background-color: $blue;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__info {
    margin-left: 24px;
    h2 {
      @extend %textSemi16;
      color: $dark;
    }
    h4 {
      @extend %textMed14;
      color: $gray80;
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
  &__title {
    color: $placeholder;
    @extend %textReg14;
  }
  &__description {
    width: max-content;
    color: $dark;
    @extend %textReg14;
    background-color: $grayF8;
    padding: 15px 24px;
    border-radius: 16px;
    margin-top: 12px;
  }
  &__address {
    width: 350px;
    color: $dark;
    @extend %textReg14;
    background-color: $grayF8;
    padding: 15px 24px;
    border-radius: 16px;
    margin-top: 12px;
  }
}
