@import "scss/main.scss";

.adminItem {
  @extend %alignCenterSpace;
  width: 100%;
  height: 84px;
  padding: 0 8px 0 5px;
  transition: all 0.2s ease;
  &:hover {
    background-color: $grayF8;
  }
  p {
    width: 350px;
    @extend %ellipsis;
    @extend %textNormal16;
  }
  &__btn {
    width: auto;
    height: 32px;
    padding: 0 17px;
    border-radius: 8px;
    @extend %textMed14;
  }
}
