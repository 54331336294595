@import "../../scss/main.scss";

.wrapper {
  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;

    & h5 {
      @include desktop_h5;
      color: $textblack;
    }
  }

  & .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0512233;
    width: 48px;
    height: 48px;
    border-radius: 15px;

    & svg {
      color: $orange;
    }
  }

  & .info {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  & .line {
    position: relative;
    flex: 1;
    background-color: $grayF4;
    border-radius: 12px;
    overflow: hidden;
    height: 24px;

    &Inner {
      background-color: $orange;
      height: 100%;
    }
    &Title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include text_small_bold;
      color: $textblack;
    }
  }
  & .plus {
    cursor: pointer;
    path {
      stroke: $textblack;
    }
  }
  & .minus {
    cursor: pointer;
    path {
      fill: $textblack;
    }
  }
}
