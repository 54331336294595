@import "../../scss/main.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.contentContainer {
  width: 375px;

  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 8px 8px 20px rgba(164, 164, 164, 0.25);
  border-radius: 20px;
  padding: 13px 17px;
  margin-right: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #665beb;
  margin-left: 4px;
  margin-right: 4px;
}

.date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
}

.bodyTitle {
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #222222;
}

.body {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #686868;

  & a {
    color: $primary;
    text-decoration: underline;
  }
}

.deleteBtn {
  cursor: pointer;
}
