@import 'scss/main.scss';

.posts {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 30px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: $grayD5;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: $blue;
    border-radius: 6px;
  }
}
