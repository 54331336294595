@import 'scss/main.scss';

.settings {
  display: flex;
  align-items: flex-start;
  &__create {
    width: 380px;
    @extend %shadowRadius;
    padding: 48px 32px;
    margin-right: 48px;
  }
  &__admins {
    width: calc(100% - 428px);
    max-height: 750px;
    min-height: 560px;
    @extend %shadowRadius;
    padding: 48px 32px;
    h4 {
      @extend %textMed14;
      color: $placeholder;
      border-bottom: 1px solid $grayD5;
      margin-bottom: 16px;
    }
    &List {
      max-height: 348px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: $grayD5;
        border-radius: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: $blue;
        border-radius: 6px;
      }
    }
  }
  &__header {
    margin-bottom: 46px;
  }
  &__btn {
    margin-top: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.toast {
  background: #00ff00 !important;
}

.settings__form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
