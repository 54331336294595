@import 'scss/main.scss';

.userRow {
  height: 80px;
  transition: all 0.2s ease;
  &:hover {
    background-color: $grayF8;
  }
  &__date {
    @extend %textMed14;
    color: $darkBlue;
  }
  td {
    min-width: 140px;
    @extend %textNormal14;
    padding-right: 15px;
    &:first-child {
      padding-left: 10px;
    }
    &:last-child {
      width: 180px;
      text-align: right;
      padding-right: 10px;
    }
  }

  &__item {
    @extend %alignCenter;
  }
  &__avatar {
    min-width: 56px;
    max-width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__info {
    margin-left: 24px;
    h4 {
      @extend %textSemi16;
    }
    button {
      @extend %button;
      @extend %alignCenter;
      background-color: transparent;
      @extend %textMed14;
      color: $gray80;
      svg {
        width: 18px;
        height: 18px;
        margin-left: 6px;
      }
    }
  }
}
