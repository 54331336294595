@import '../../scss/main.scss';

.wrapper {
  padding: 17.5px 0px;
  display: flex;
  align-items: center;
  border-top: 1px solid $grayE4;
  position: relative;

  & .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-family: $inter-font;
    margin-left: 15px;
    flex: 1;
  }

  & .category {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: $inter-font;
    flex: 1;
  }

  & .dateWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      font-family: $inter-font;
    }
  }

  & .menuWrapper {
    width: 109px;
    position: absolute;
    right: -20px;
    top: 45px;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
    background: #fff;
    z-index: 99;
    padding: 10px;

    & .menuButton {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 6px 8px;
      border-radius: 10px;
      transition: all linear 0.2s;
      cursor: pointer;

      & .deleteLoader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background: #f8f8f8;
      }

      & span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
        color: #000;
      }
    }
  }
}

.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
}
.modal {
  outline: none;
  & .modalInner {
    width: 450px;
    height: 374px;
    background: $white;
    border-radius: 24px;
    padding: 32px;
    position: relative;
    outline: none;

    & .name {
      margin-bottom: 10px;
    }

    & .category {
      margin-bottom: 22px;
    }

    & .loader {
      display: flex;
      width: 100%;
      height: 80%;
      align-items: center;
      justify-content: center;
    }

    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    & .closeBtn {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
