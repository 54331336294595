@import "../../scss/main.scss";

.sidebar {
  @extend %flexColumn;
  justify-content: space-between;
  min-width: 266px;
  min-height: 100vh;
  max-height: 100vh;
  padding: 48px 20px;
  border-right: 1px solid $grayE4;
  position: fixed;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: $grayE4;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grayE4;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-left: 5px;
    margin-bottom: 48px;
    font-size: 28px;
    font-weight: 700;
    color: "#000";
  }
  .boostCamp {
    display: flex;
    align-items: center;
    // position: relative;
    // &[class*="active"] {}
    .arrow {
      position: relative;
      left: 20%;
      transition: 1ms;
      animation-name: arrow;
      animation-duration: 0.5s;
    }
    .home__icon {
      fill: "#000";
      // transform: 1000ms rotate(180deg);
    }
  }
  &__row {
    h4 {
      @extend %textNormal14;
      color: $placeholder;
      margin: 0 0 16px 20px;
    }
  }
  &__menu {
  }
  &__profile {
    h4 {
      @extend %textMed14;
      margin-bottom: 2px;
    }
    button {
      @extend %button;
      background-color: transparent;
      @extend %textNormal14;
      color: $gray80;
    }
  }
}

.dropdownBtn {
  @extend %alignCenter;
  padding: 0 20px;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  @extend %textMed14;
  color: $gray80;
  transition: all 0.1s ease;
  margin-top: 5px;
  svg {
    margin-right: 8px;
  }

  & .arrowWrapper {
    display: flex;
    align-items: center;
    justify-items: center;
    margin-left: auto;
    transition: all 2s;
    & svg {
      margin-right: 0;
      transform: rotate(180deg);
    }
  }

  &:hover {
    cursor: pointer;
  }

  &Active {
    background-color: $primary;
    color: $white;

    & .arrowWrapper {
      display: flex;
      align-items: center;
      justify-items: center;
      margin-left: auto;
      transition: all 2s;
      & svg {
        margin-right: 0;
        transform: rotate(360deg);
      }
    }

    svg {
      fill: $white;
    }
    svg > path {
      // fill: $white;
      stroke: $white;
    }
    svg > circle {
      // fill: $white;
      stroke: $white;
    }
  }
}

.arrowWrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  & svg {
    margin-right: 0;
  }
}

.navigationBtnWrapper {
}

.navigationBtn {
  @extend %alignCenter;
  padding: 0 20px;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  @extend %textMed14;
  color: $gray80;
  transition: all 0.1s ease;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  &Active {
    background-color: #8c42e326;
    color: $primary;
  }
}

.border {
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: $grayE4;
}

.link {
  @extend %alignCenter;
  padding: 0 20px;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  @extend %textMed14;
  color: $gray80;
  transition: all 0.1s ease;
  margin-top: 5px;
  svg {
    margin-right: 8px;
  }

  &Menu {
    @extend %alignCenter;
    padding: 0 20px;
    width: 100%;
    height: 56px;
    border-radius: 12px;
    @extend %textMed14;
    color: $gray80;
    transition: all 0.1s ease;
    margin-top: 5px;
    svg {
      margin-right: 8px;
    }
    &[class*="active"] {
      background-color: #8c42e326;
      color: $primary;
      svg {
        fill: $primary;
      }
      svg > path {
        // fill: $white;
        stroke: $white;
      }
      svg > circle {
        // fill: $white;
        stroke: $white;
      }
    }
  }

  &[class*="active"] {
    background-color: $primary;
    color: $white;
    svg {
      fill: $primary;
    }
    svg > path {
      // fill: $white;
      stroke: $white;
    }
    svg > circle {
      // fill: $white;
      stroke: $white;
    }
  }
}
