@import "../../../scss/main.scss";

.wrapper {
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $grayE4;
  overflow: hidden;
}

.input {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 20px;
  @include text_medium_bold;
  border: none;
  outline: none;
  width: 100%;
}

.arrowBtnWrapper {
  margin-right: 15px;

  & .increaseBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & .decreaseBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & svg {
      transform: rotate(180deg);
    }
  }
}
.label {
  @include text_small_bold;
  margin-bottom: 5px;
  max-width: 100px;
}
