@import "../../../scss/main.scss";

.contentBox {
  width: 407px;

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 18px;

    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 15px;
      background: #8c42e326;
    }

    & h5 {
      @include desktop_h5;
    }
  }

  & .sliderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;

    & .recommended {
      @include text_small_regular;
      color: $textdark;
      margin-bottom: 15px;

      & b {
        @include text_small_bold;
        color: $primary;
      }
    }

    & h2 {
      @include desktop_h2;
    }

    & .sliderOptionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 14px;
      & .sliderOption {
        @include text_x_small_regular;
        color: $grayAf;
      }
    }
  }
}
