@import 'scss/main.scss';

.reportRow {
  height: 110px;
  &__date {
    @extend %textMed14;
    color: $darkBlue;
  }
  &__btn {
    width: auto;
    height: 32px;
    padding: 0 17px;
    border-radius: 8px;
    @extend %textMed14;
  }
  &__copy {
    cursor: pointer;
  }
  &__copyTd {
    display: flex;
    height: inherit;
    align-items: center;
  }
  &__copyField {
    margin-left: 10px;
    background-color: $darkGray;
    color: white;
    padding: 0 5px;
    border-radius: 8px;
    opacity: 1;
  }
  &__copyField1 {
    margin-left: 10px;
    background-color: $darkGray;
    color: white;
    padding: 0 5px;
    border-radius: 8px;
    opacity: 0;
  }
  td {
    @extend %textNormal14;
    &:last-child {
      text-align: right;
    }
  }
  &__text {
    p {
      background-color: $grayF8;
      padding: 15px 24px;
      border-radius: 16px;
      @extend %textNormal16;
      color: $dark;
    }
    td {
      padding-bottom: 32px;
      border-bottom: 1px solid #d5d5d6;
    }
  }
}
