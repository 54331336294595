@import "../../scss/main.scss";

.wrapper {
  margin-top: 10px;
}

.title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}

.question {
  margin-top: 10px;
  background-color: $grayE4;
  padding: 18px 24px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $gray80;
  border: none;
  &:focus {
    border: none;
  }
  &:active {
    border: none;
    outline: none;
  }
}

.flex {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.answerBlock {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 15px;
}

.deleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 56px;
  margin-left: 10px;

  border-radius: 16px;
  border: 2px solid $red;

  cursor: pointer;
}
