@import "../../scss/main.scss";

.wrapper {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  width: 49%;
  border: 1px solid #f8f8f8;

  &_error {
    border-color: $red;
  }

  & .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    font-family: $inter-font;
  }

  & .attachBtn {
    margin-top: 10px;
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border: 2px dashed $primary;
    border-radius: 16px;
    width: 100%;
    color: $primary;
    font-family: $inter-font;
    cursor: pointer;
  }

  & .mealPlanContainer {
    display: flex;
    gap: 10px;
    padding: 18px;
    background: rgba(140, 66, 227, 0.15);
    border-radius: 16px;
    margin-top: 10px;
    cursor: pointer;

    & .infoBlock {
      flex: 1;
      & .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        font-family: $inter-font;
        color: $primary;
      }
      & .description {
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #808191;
      }
    }
  }
}
