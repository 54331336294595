@import "../../../scss/main.scss";

.wrapper {
  max-width: 1280px;
  margin: 30px auto;
  padding: 0 50px;

  & .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & .title {
      width: 290px;
      text-align: center;
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      margin: 0 10px;
    }

    & .prevBtn {
      width: 60px;
      height: 60px;
      border: 1px solid #e4e4e4;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  & .loader {
    display: flex;
    height: 60vh;
    align-items: center;
    justify-content: center;
  }

  & .contentContainer {
    display: flex;
    margin-top: 50px;

    gap: 30px;
    & .daysContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 298px;

      & .dayItem {
        padding: 15px 30px;
        background: #fff;
        border-radius: 30px;
        cursor: pointer;

        & span {
          font-weight: 800;
          font-size: 24px;
          line-height: 28px;
        }
      }

      & .dayItemActive {
        border: 4px solid #8c42e3;
        padding: 12px 26px;
      }
    }

    & .recipeContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;

      & .recipeItemWrapper {
        padding: 30px;
        background: #fff;
        border-radius: 30px;

        & .title {
          @include desktop_h4;
        }

        & .descriptionWrapper {
          background: #f8f8f8;
          padding: 20px;
          border-radius: 20px;
          margin-top: 20px;

          & p {
            @include text_large_regular;
          }
        }
      }
    }
  }
}

.mealPlanBtn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #60C055;
  border-radius: 16px;
  padding: 18px 18px;
  color: #fff;
  cursor: pointer;
}

