@import 'scss/main.scss';

.post {
  display: flex;
  align-items: flex-start;
  padding-bottom: 32px;
  border-bottom: 1px solid $grayD5;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  &__cover {
    width: 400px;
    margin-right: 40px;
  }
  &__info {
    width: calc(100% - 440px);
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    p {
      @extend %textNormal14;
      color: $placeholder;
      margin-bottom: 12px;
    }
  }
  &__photo {
    width: 100%;
    height: 200px;
    background-color: $blue;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      // height: 100%;
    }
    p {
      color: white;
      font-size: 25px;
    }
  }
  &__value {
    padding: 15px 24px;
    background-color: $grayF8;
    border-radius: 16px;
    @extend %textNormal16;
    color: $dark;
  }
  &__delete {
    &Btn {
      text-align: right;
    }
    width: 136px;
    padding: 15px 24px;
    background-color: $red;
  }
}
