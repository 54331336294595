@import 'scss/main.scss';

.manageTable {
  @extend %flexColumn;
  flex-grow: 1;
  margin-top: 40px;
  &__inner {
    flex: 1 1 auto;
    height: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  table {
    @extend %table;
    thead {
      position: sticky;
      top: 0;
      width: 100%;
      height: 36px;
      background-color: $white;
      outline: 1px solid #d5d5d6;
      td {
        padding-left: 10px;
        padding-right: 10px;
        @extend %textMed14;
        color: $placeholder;
        min-width: 140px;
        max-width: 140px;
      }
      // td:nth-child(2) {
      //   text-align: center;
      // }
      td:nth-child(4) {
        text-align: right;
      }
    }
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    margin-top: 10px;
  }
}
