@import "../../scss/main.scss";

.wrapper {
  display: flex;
  width: max-content;
  border-radius: 8px;
  background: rgba(140, 66, 227, 0.15);
  padding: 7px 10px;
  cursor: pointer;

  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    font-family: $inter-font;
    color: $primary;
    margin-left: 7px;
  }
}

.selectedWrapper {
  background: $primary;

  & span {
    color: #fff;
  }
}
