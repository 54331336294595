@import "../../../../scss/main.scss";
.wrapper {
  max-width: 1440px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-family: $inter;
    font-size: 48px;
    line-height: 78px;
    font-weight: 600;
  }
}

.tutorialListContainer {
  margin-top: 30px;
}

.tutorialList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
