@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 56px;
  margin-bottom: 10px;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
}

.titleInputWrapper {
  flex: 1;
  margin-right: 10px;
}
.pointInputWrapper {
  width: 135px;
}
