@import "scss/main.scss";

.reportTable {
  flex-grow: 1;
  overflow-y: hidden;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  &::-webkit-scrollbar {
    display: none;
  }
  table {
    @extend %table;

    thead {
      top: 0;
      height: 36px;
      outline: 1px solid #d5d5d6;
      position: sticky;

      td {
        @extend %textMed14;
        color: $placeholder;
        background-color: $white;
      }
    }
  }
}

.reportTableDown {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  &::-webkit-scrollbar {
    display: none;
  }
  table {
    @extend %table;
    thead {
      position: sticky;
      top: 0;
      height: 36px;
      background-color: $white;
      outline: 1px solid #d5d5d6;
      td {
        @extend %textMed14;
        color: $placeholder;
      }
    }
  }
}
