@import "scss/main.scss";

.alert {
  position: relative;
  width: 500px;
  min-height: 200px;
  background-color: $white;
  box-shadow: 0px 12px 54px rgba(228, 247, 248, 0.54);
  border-radius: 16px;
  outline: none;
  padding: 24px;

  &__close {
    @extend %button;
    background-color: transparent;
    position: absolute;
    top: 24px;
    right: 24px;
  }
  h2 {
    @extend %textSemi20;
    color: $dark;
    padding-right: 40px;
    margin-bottom: 16px;
  }
  p {
    @extend %textNormal16;
    color: $dark;
    margin-bottom: 32px;
  }
  &__btns {
    @extend %alignCenter;
    justify-content: center;
  }
  &__btn {
    width: auto;
    padding: 11px 24px;
    &.main {
      background-color: $red;
      margin-left: 12px;
    }
  }
}
