@import "../../../../scss/main.scss";

.wrapper {
  max-width: 1440px;
}

.headerNotificationBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 0px 5px 5px #e3e6ec;
  cursor: pointer;
}

.greenBtn {
  background: #60c055;
  &:hover {
    background: #60c055;
  }

}

.headerNotificationCounter {
  margin-top: -52px;
  margin-left: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  background-color: #ff754c;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

.bootcampsSettingsWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bootcampsSettingsLeftContent {
  width: 50%;
}

.bootcampsSettingsRightContent {
  width: 47%;
}

.bootcampsSettingsNumbersOfDayWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bootcampsSettingsNumbersOfDayInp {
  width: 48%;
}

.bootcampsSettingsPriceWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bootcampsSettingsDiscountWrapper {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bootcampsSettingsDiscount {
  width: 55%;
}

.bootcampsSettingsDiscountOption {
  width: 40%;
}

.bootcampsSettingsPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 332px;
  width: 100%;
  background-color: $grayE4;
  object-fit: cover;
  background-size: cover;
  margin-bottom: 20px;
}

.bootcampsSettingsUploadImgBtn {
  display: none;
}

.bootcampsSettingsUploadImgWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  width: 166px;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.flex {
  display: flex;
  flex-direction: row;
}

.bootcampsSettingsDeleteImgBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grayE4;
  width: 166px;
  height: 40px;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-left: 10px;
}

.faqSectionWrapper {
  border-top: 1px solid $grayE4;
  margin-top: 30px;
  padding-top: 30px;
}

.faqTitle {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.faqAddQuestionBtn {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 167px;
  border: 2px dashed $blue;
  border-radius: 16px;
  cursor: pointer;
}

.faqAddQuestionBtnTitle {
  margin-left: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: $blue;
}

.reviewsSectionWrapper {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $grayE4;
}

.reviewsSectionWrapper {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.reviewsSectionSubTitle {
  margin-top: 10px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $gray80;
}

.saveSectionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $grayE4;
  padding-bottom: 40px;
}

.saveBtn {
  margin-right: 10px;
  height: 56px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: "#fff";
  font-size: 14px;
  font-weight: 600;
}

.btnsContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
}
