@import '../../../../../scss/main.scss';

.headerContainer {
  & .headerTitle {
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
  }

  & .navigationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 30px;

    & .navLink {
      padding: 8px 18px;
      border-radius: 12px;
      border: 2px solid $grayE4;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      margin-right: 18px;
      cursor: pointer;

      &Active {
        color: $primary;
        background-color: rgba(140, 66, 227, 0.15);
      }
    }

    & .totalPoints {
      margin-left: auto;
      margin-right: 200px;
      font-size: 14px;
      font-weight: 500;
      & span {
        color: $primary;
      }
    }
  }
}
