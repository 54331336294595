//colors
$white: #fff;
$black: #000;
$pink: #fc9696;
$pinkBg: #ffefef;
$dark: #2b2d33;
$darkBlue: #04607d;
$red: #ee3758;
$grayF8: #f8f8f8;
$grayE9: #e9eaea;
$gray91: #808191;
$gray80: #808185;
$darkGray: #888888;
$grayD5: #d5d5d6;
$grayE4: #e4e4e4;
$grayF4: #f4f4f4;
$placeholder: #aaabad;
$dis: rgba(43, 45, 51, 0.24);
$green: #a5d05f;
$greenCounter: #7fba7a;
$blue: #8c42e3;
$blueBg: #e8f7f4;
$violet: #be93f8;
$primary: #8c42e3;
$primaryOpacity: #8c42e333;

$white: #ffffff;
$black: #000000;
$textblack: #131313;
$textdark: #3a3a3a;
$grayAf: #afafaf;
$grayE4: #e4e4e4;
$grayF8: #f8f8f8;
$gray3A: #3a3a3a;
$gray7C: #7c8691;
$green: #60c055;
$orange: #f05122;
$mint: #79dcd5;
$yellow: #fcd930;
$primary: #8c42e3;
$primaryOpacity: #8c42e326;
$error: #ee3758;

//fonts
$inter: "Poppins", sans-serif;
$inter-font: "Inter", sans-serif;
$primary-font: "Poppins", sans-serif;
$secondary-font: "Poppins", sans-serif;
