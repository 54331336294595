@import '../../scss/main.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 480px;
  width: 100%;
}

.inputWrapper {
  width: 100%;
}

.deleteBtn {
  width: 36px;
  margin-left: 15px;
  cursor: pointer;
}

.faqAddQuestionBtn {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 167px;
  border: 2px dashed $blue;
  border-radius: 16px;
  cursor: pointer;
}

.faqAddQuestionBtnTitle {
  margin-left: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: $blue;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
