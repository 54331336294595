@import "../../scss/main.scss";

.wrapper {
  border-radius: 30px;
  background: $white;
  padding: 30px;
  margin-bottom: 40px;
  width: 407px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 5px;
  }

  & .title {
    @include desktop_h4;

    @media (max-width: 768px) {
      @include mobile_h4;
    }
  }

  & .remindersList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  & .reminderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    & svg {
      color: $primary;
      min-width: 24px;
      height: 24px;
    }

    & .reminder {
      @include text_medium_regular;
      margin-left: 10px;
      max-width: 80%;
      word-wrap: break-word;
    }
  }

  & h5 {
    @include desktop_h5;

    & span {
      @include desktop_h4;
      color: $primary;
    }
  }
}
