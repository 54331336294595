@import "scss/main.scss";

.backBtn {
  @extend %button;
  display: flex;
  background-color: transparent;
  @extend %textNormal16;
  color: $gray80;
  svg {
    margin-right: 8px;
  }
}
