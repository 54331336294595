@import "scss/main.scss";

.wrapper {
  width: 306px;
  height: 56px;
  background-color: $grayE4;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 23px;
  padding-right: 23px;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $grayD5;
  }
}

.title {
  font-size: 14px;
  font-family: 600;
}

.arrow svg {
  transform: rotate(-90deg);
}

.arrowDown svg {
  transform: rotate(90deg);
}

.optionsList {
  width: 306px;
  height: 100px;
  background-color: $grayE4;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  transition: all 0.2s;
}
