@import "../../../../scss/main.scss";

.wrapper {
  max-width: 1440px;

  & .header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-left: 10px;
    }

    & .back {
      cursor: pointer;
      display: flex;
    }
  }

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
  & .input {
    margin-bottom: 16px;
  }
  // & h1 {
  //   font-weight: 600;
  //   font-size: 36px;
  //   line-height: 54px;
  //   margin-bottom: 30px;
  // }

  & .headerInputWrapper {
    width: 560px;
    padding-bottom: 20px;
  }

  & .shortDescription {
    height: 100px;
  }

  & .divider {
    width: "100%";
    height: 1px;
    background: $grayE4;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & .footer {
    display: flex;
    align-items: center;
    padding: 30px 0px;
    border-top: 1px solid $grayE4;

    & .cancelBtn {
      background: $grayE4;
      color: #000;
      width: 166px;
    }

    & .saveBtn {
      width: 166px;
      margin-left: 10px;
    }
  }

  .imageWrapper {
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      height: 360px;
      width: 100%;
      max-width: 560px;
      background-color: #e4e4e4;
      object-fit: cover;
      background-size: cover;
      margin-bottom: 20px;
    }
  }
}
