@import 'scss/main.scss';

.inner {
  margin-top: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  box-sizing: border-box;
  width: fit-content;
}

.dataUser {
  margin-top: 20px;
  font-weight: 500;
  color: $gray80;
}

.data {
  margin-top: 20px;
  font-weight: 500;
  color: $gray80;
}

.dataHandle {
  margin-top: 10px;
  font-weight: 500;
  margin-left: 20px;
  color: $gray80;
}
