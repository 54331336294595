@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding: 12px 12px;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid $grayE4;
}

.activeWrapper {
  background-color: $primary;
  border-color: $primary;
  padding: 10px 10px;
}

.completeWrapper {
  border-color: rgba(96, 192, 85, 0.2);
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: $inter-font;
  margin-left: 5px;
}

.completeTitle {
  color: $green;
}

.default {
  width: 6px;
  height: 6px;
  background: $grayE4;
  border-radius: 100px;
  margin-right: 10px;
}

.activeTitle {
  color: $white;
}
