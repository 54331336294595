@import "scss/main.scss";

.signIn {
  width: 100%;
  height: 100vh;
  @extend %flexCenter;
  background-color: $blueBg;

  &__inner {
    width: 515px;
    height: 700px;
    @extend %shadowRadius;
    padding: 25px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 190px;
    margin-left: 5px;
    margin-bottom: 48px;
    font-size: 36px;
    font-weight: 700;
    color: "#000";
    // margin: 0 auto 16px;
  }
  &__form {
    width: 316px;
    margin: 0 auto;
  }
  &__btn {
    margin-top: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__error {
    font-size: 15px;
    color: $red;
    text-align: center;
  }
}
