@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  padding: 30px;
  height: 145px;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  position: relative;
  // margin-bottom: 20px;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
  }
}
.photoContainer {
  width: 110px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 20px;
}

.photo {
  width: 100%;
  height: auto;
}

.photoLoader {
  position: absolute;
  display: flex;
  width: 110px;
  height: 85px;
  border-radius: 15px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: $grayE9;
}

.isDarft {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 50px;

  & .isDarftTitle {
    font-size: 12px;
    color: $gray91;
    margin-bottom: 5px;
  }
}

.titleWrapper {
  flex: 1;
  margin-right: 82px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $textdark;
  margin-top: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  max-height: 48px;
  max-width: 1070px;
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $gray91;
  margin-top: 5px;
}

.priceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 82px;
}

.priceWithDiscount {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-right: 15px;
}

.price {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: $gray91;
  text-decoration: line-through;
}

.filterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
}

.menuWrapper {
  position: absolute;
  right: 10px;
  top: 100px;
  width: 135px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  & .copyIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      color: #000;
    }
  }
}

.order {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin: 0 10px;
}
