@import "../../scss/main.scss";
.wrapper {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid $grayE4;
  position: relative;
  & .descriptionWrapper {
    flex: 1;
    margin-left: 16px;

    & .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    & .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray91;
      margin-top: 5px;
    }
  }
  & .ingredientsContainer {
    display: flex;
    align-items: center;
    width: 250px;

    & .ingredientsWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      &Title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-family: $inter-font;
        color: #3a3a3a;
        margin-left: 10px;
      }
    }
    & .dotsMenu {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
.menuWrapper {
  position: absolute;
  right: -20px;
  top: 60px;
  width: 125px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  & .copyIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      color: #000;
    }
  }
}

.dotsWrapper {
  margin-left: auto;
}
