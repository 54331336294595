@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  height: 350px;
  // margin-bottom: 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  & h4 {
    @extend %textNormal14;
    color: $placeholder;
    margin-bottom: 6px;
  }
}
