@import 'scss/main.scss';

.tag {
  // display: inline-block;
  max-width: 200px;
  margin: 0;
  @extend %textNormal14;
  color: $dark;
  @extend %ellipsis;
}
