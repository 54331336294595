@import "../../scss/main";

.wrapper {
  margin-top: 30px;
}

.headerTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 10px;
}

.headerInputWrapper {
  flex: 1;
  margin-right: 10px;
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dropDown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 168px;
  height: 56px;
  background-color: $grayF8;
  border-radius: 8px;
  padding: 15px 18px;
  font-size: 14px;
  font-family: $inter;
  font-weight: 600;
  line-height: 22px;
  border: 2px solid $grayF8;

  outline: none;

  &.dropDownActive {
    border: 2px solid $primary;
  }

  &:focus {
    border: 2px solid $primary;
    outline: none;
  }

  & .arrowWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
  }

  & .arrowWrapperActive {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
  }
}
.selectWrapper {
  flex: 0
}

.dropDownList {
  position: absolute;
  width: 101%;
  left: -2px;
  bottom: 56px;
  border-radius: 8px;
  border: 2px solid $grayE4;
  background-color: $grayF8;
}
.dropDownItem {
  padding: 15px 18px;
  height: 56px;
  &:not(:last-child){
    border-bottom: 2px solid $grayE4;

  }
}

.deleteBtn {
  border: 2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 56px;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }
}

.contentTitle {
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.contentContainer{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.leftContainer {
  margin-right: 50px;
}

.rightContainer {
  flex: 1;
}



.bootcampsSettingsPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 325px;
  max-width: 489px;
  width: 100%;
  background-color: $grayE4;
  object-fit: cover;
  background-size: cover;
  margin-bottom: 20px;
}

.bootcampsSettingsPhotoError {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 325px;
  max-width: 489px;

  width: 100%;
  background-color: $grayE4;
  object-fit: cover;
  background-size: cover;
  margin-bottom: 20px;
  border: 2px solid $red;
}

.bootcampsSettingsUploadImgBtn {
  display: none;
}

.bootcampsSettingsUploadImgWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  width: 166px;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.flex {
  display: flex;
  flex-direction: row;
}

.bootcampsSettingsDeleteImgBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grayE4;
  width: 166px;
  height: 40px;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-left: 10px;
}

.textArea {
  height: 325px;

}
