@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $grayE4;
  position: relative;
  transition: all linear 0.2s;

  &:hover {
    background: rgba(250, 250, 250, 1);
    cursor: pointer;
  }

  &:first-child {
    // border-top: 1px solid $grayE4;
  }

  & .img {
    width: 70px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
    margin-left: 16px;
  }

  & .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-family: $inter-font;
    margin-left: 15px;
    flex: 1;
  }

  & .ingredientTitle {
    width: 113px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: $inter-font;
  }

  & .cookTime {
    width: 100px;
    margin-left: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: $inter-font;
  }

  & .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 269px;
    margin-left: 25px;

    & .tag {
      padding: 3px 10px;
      background: rgba(140, 66, 227, 0.15);
      border-radius: 8px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: $primary;
    }
  }

  & .menuBtn {
    cursor: pointer;
  }
}
.menuWrapper {
  position: absolute;
  right: -20px;
  top: 60px;
  width: 135px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  & .copyIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      color: #000;
    }
  }
}
