@import "../../scss/main.scss";

.wrapper {
  background: #ffffff;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 286px;
  overflow: hidden;
  z-index: 10;
  & .searchInputWrapper {
    display: flex;
    align-items: center;
    background: #e4e4e430;
    padding: 0px 18px;
    border-radius: 16px;
    border: 3px solid $primaryOpacity;

    & .searchInput {
      flex: 1;
      background: transparent;
      border: none;
      outline: none;
      height: 50px;
      margin-left: 16px;
      font-weight: 600;
      font-size: 14px;
      font-family: $inter-font;
    }
  }

  & .list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: $grayE4;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $grayE4;
    }
    & .listItem {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-family: $inter-font;
      padding: 6px 8px;
    }
    & .listLoading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
    }

    & .listLoadingPagination {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
    }
  }
}
