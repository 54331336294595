@import "scss/main.scss";

.dashboard {
  display: flex;
  &__inner {
    margin-left: 256px;
    flex: 1 1 auto;
    height: 100vh;
    padding: 48px 48px 64px;
  }
}
