@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  // display: flex;

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }
}

.arrow {
  width: 58px;
  height: 58px;
  background-color: rgba($textblack, 0.6);
  border-radius: 15px;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: rgba($textblack, 0.6);
  }

  &::before {
    display: none;
  }

  svg path {
    stroke: white;
  }
}
