@import "../../../../../scss/main.scss";
@mixin progressLine {
  width: 147.5px;
  height: 5px;
  border-radius: 10px;
  margin: 2.5px;
  background-color: #8c42e3;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .totalPoints {
    font-size: 14;
    font-weight: 500;

    & span {
      color: $primary;
    }
  }
  .headerTitle {
    & h1 {
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
      color: "#000";
    }
  }
  .headerSteps {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .text {
      display: flex;
      line-height: 21px;
      word-spacing: 2px;
      font-weight: 600;
      margin-bottom: 10px;
      .boldText {
        font-size: 18px;
      }
      .grayText {
        color: #b7b8b9;
        font-size: 14px;
        margin-left: 5px;
        display: flex;
        .slash {
          font-size: 12px;
          margin-right: 2px;
        }
      }
    }
    .lines {
      display: flex;
      flex-direction: center;
      align-items: center;
      .activeLine {
        @include progressLine();
      }
      .inActiveLine {
        @include progressLine();
        opacity: 0.15;
      }
    }
  }
}
