@import 'scss/main.scss';

.input {
  position: relative;
  & .dataListWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    z-index: 10;
    background-color: $grayF8;
    border-radius: 8px;

    & .dataList {
      max-height: 230px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background: $grayE4;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $grayE4;
      }

      & .dataItem {
        padding: 15px 18px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid $grayE4;
        }
      }
    }

    & .searchInputWrapper {
      display: flex;
      align-items: center;
      background: #e4e4e455;
      padding-left: 18px;
      padding-right: 4px;

      & .searchInput {
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        max-width: 100%;
        height: 51px;
      }
    }
  }
  &__main {
    width: 100%;
    height: 56px;
    @extend %input;
    background-color: $grayF8;
    border-radius: 8px;
    padding: 15px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    caret-color: $blue;
    color: #000;
    cursor: pointer;
    border: 1px solid $grayF8;
    display: flex;
    align-items: center;
    position: relative;

    & .arrow {
      transform: rotate(90deg);
      transition: all linear 0.2s;
      width: 12px;
    }

    & .openArrow {
      transform: rotate(270deg);
    }

    & .value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
    }

    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      border-color: $blue;
      border-width: 2px;
    }
  }
  &:not(:last-child) {
    // margin-bottom: 18px;
  }
  h4 {
    @extend %textNormal14;
    color: $placeholder;
    margin-bottom: 6px;
  }
  p {
    position: absolute;
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}
