@import "../../../scss/main.scss";

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.leftContentContainer {
  width: 50%;
}
.leftWrapper {
  margin-top: 24px;
  max-width: 350px;
  width: 100%;
}

.loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.previewWrapper {
  position: absolute;
  z-index: 99999;
  left: 0;
  bottom: 15px;
}

.rightContentContainer {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 450px;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.sendBtnWrapper {
  width: 200px;
  margin-top: 34px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.dropDown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background-color: $grayF8;
  border-radius: 8px;
  padding: 15px 18px;
  font-size: 14px;
  font-family: $inter;
  font-weight: 600;
  line-height: 22px;
  border: 2px solid #fff;
  outline: none;
  cursor: pointer;

  & .dropDownTitle {
    font-size: 14px;
  }

  &.dropDownActive {
    border: 2px solid $primary;
  }

  &:focus {
    border: 2px solid $primary;
    outline: none;
  }

  & .arrowWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
  }

  & .arrowWrapperActive {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
  }
}

.dropDownList {
  position: absolute;
  width: 101%;
  height: auto;
  left: -2px;
  bottom: 56px;
  border-radius: 8px;
  border: 2px solid #ccc;
  background-color: $grayF8;
}
.dropDownItem {
  padding: 15px 18px;
  height: auto;
  border-bottom: 2px solid #ccc;
}

.copiedWrapper {
  position: absolute;
  padding: 4px 6px;
  border-radius: 6px;
  background: $white;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25);
  border: 1px solid #e4e4e4;
  bottom: 34px;
  right: 20px;
}

.addLinkWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;

  & .addLinkBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(140, 66, 227, 0.15);
    padding: 6px 10px;
    border-radius: 4px;
    color: rgba(140, 66, 227, 1);
    font-family: $inter;
    font-size: 14px;
    font-weight: 500;
  }

  & .addLinkInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    z-index: 9999;
    bottom: 0px;
    width: 250px;
    background: white;
    border-radius: 6px;
    padding: 6px;
    border: 1px solid #e4e4e4;
    align-items: flex-end;
    box-shadow: 8px 8px 20px rgba(164, 164, 164, 0.25);
    & .linkInput {
      outline: none;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      padding: 6px 10px;
      width: 100%;
      font-size: 12px;
      font-family: $inter;
    }

    & .save {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      background: #60c05501;
      border: 0.5px solid #60c055;
      border-radius: 4px;
      padding: 2px 5px;
      color: #60c055;
      font-size: 12px;
      font-family: $inter;
      cursor: pointer;
    }
  }
}
