@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  height: 300px;
}

.chartsControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 28px;
}

.activeControlsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 12px;
  height: 36px;
  background-color: $primary;
  cursor: pointer;
}

.contorlsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 12px;
  height: 36px;
  cursor: pointer;
}

.activeControlsBtnText {
  font-family: $inter;
  color: $white;
}
