@import "scss/main.scss";

.input {
  position: relative;
  &__main {
    width: 100%;
    height: 220px;
    @extend %input;
    background-color: $grayF8;
    border-radius: 8px;
    padding: 15px 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    caret-color: $blue;
    resize: none;
    &::placeholder {
      color: $placeholder;
    }
    border: 1px solid $grayF8;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
      border: 1px solid $red;
    }
    &:focus {
      border-color: $blue;
      border-width: 2px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  h4 {
    @extend %textNormal14;
    color: $placeholder;
    margin-bottom: 6px;
  }
  p {
    position: absolute;
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}
