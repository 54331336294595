@import "../../../scss/main.scss";

.dividerHeader {
  height: 48px;
}
.contentWrapper {
  background-color: $white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  padding: 30px;
  height: 360px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
  background-color: $white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  padding: 30px;
}

.graphicsWrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
}

.bigCounter {
  margin-top: 17px;
  font-size: 56px;
  font-family: $inter;
  color: #000;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 1px solid $grayE4;
}

.smallCounter {
  font-size: 32px;
  font-family: $inter;
  font-weight: 600;
  color: #000;
  line-height: 48px;
  margin-right: 15px;
}

.topTitle {
  font-family: $inter;
  color: #000;
  font-size: 22px;
}

.todayTitle {
  font-family: $inter;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

.subtitle {
  font-family: $inter;
  margin-top: 8px;
  color: $darkGray;
}

.todayStatistics {
  margin-top: 24px;

  flex-direction: row;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.greenText {
  color: $greenCounter;
  margin-left: 4px;
}

.statisticsContainer {
  display: flex;
  justify-content: space-between;
}

.statisticItem {
  padding-top: 30px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statisticItem:not(:last-child) {
  border-right: 1px solid $grayE4;
}

.statisticTitle {
  font-family: $inter;
  font-size: 22px;
  line-height: 24px;
  color: black;
  margin-bottom: 10px;
  font-weight: 500;
}

.statisticPrice {
  font-family: $inter;
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 1px solid $grayE4;
}

.statisticTodayTitle {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  font-family: $inter;
  margin-bottom: 10px;
}

.statisticTodayPrice {
  font-family: $inter;
  color: #000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-right: 13px;
}

.statisticIncrementTitle {
  margin-left: 4px;
  font-family: $inter;
  color: $greenCounter;
}

.verticalDivider {
  height: 100%;
  width: 1px;
  background-color: #000;
}
