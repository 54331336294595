@import "../../scss/main.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  & .title {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    @include text_medium_regular;
    color: $gray3A;
  }

  & .checkedTitle {
    text-decoration: line-through;
  }

  & .points {
    flex: 0;
    @include text_medium_bold;
    color: $primary;
  }

  & .checkedPoints {
    color: $grayAf;
  }
}
