@import 'scss/main.scss';

.pagination {
  margin-top: 10px;
  @extend %alignCenter;
  p {
    @extend %textMed16;
    color: $dark;
    margin: 0 6px;
  }
  button {
    @extend %flexCenter;
    @extend %button;
    width: 32px;
    height: 32px;
    background-color: transparent;
    &.left {
      transform: rotate(-180deg);
    }
  }
}
