@import '../../scss/main.scss';

.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
  overflow-y: auto;
}
.modal {
  height: 70vh;
  outline: none;
  border: none;
  & .modalInner {
    width: 730px;
    background: #fff;
    border-radius: 30px;
    overflow: hidden;

    & .header {
      width: 730px;
      height: 440px;
      position: relative;
      & .headerImg {
        height: 440px;
        width: 730px;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
      }
      & .closeBtn {
        position: absolute;
        width: 42px;
        height: 42px;
        background: rgba(19, 19, 19, 0.5);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        right: 20px;
        z-index: 999;
        cursor: pointer;
      }

      & .headerInfo {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 150px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );

        & .title {
          width: 660px;
          overflow: hidden;
          font-weight: 800;
          font-size: 40px;
          line-height: 48px;
          color: #fff;
          margin-bottom: 20px;
          overflow-wrap: break-word;
        }

        & .footer {
          display: flex;
          align-items: center;
          gap: 20px;

          & .footerItem {
            display: flex;
            align-items: center;
            & svg {
              width: 20px;
              height: 20px;
              color: #fff;
            }

            & span {
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
              color: #fff;
              margin-left: 7px;
              font-family: $inter-font;
            }
          }
        }
      }
    }

    & .contentContainer {
      padding: 5px 30px;
      & .shortOverview {
        margin-bottom: 15px;
        overflow-wrap: break-word;
      }
      & .sectionTitle {
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      & .ingredientItem {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &Link {
          cursor: pointer;
          color: $primary;
        }

        & .amountWrapper {
          overflow: hidden;
          width: auto;
          min-width: 136px;
          padding: 3px 10px;
          background: $grayF8;
          border-radius: 10px;
          border-width: 1px solid black;
          display: flex;
          justify-content: flex-end;
          & p {
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            font-family: $inter-font;

            & span {
              color: #3a3a3a;
              margin: 0 5px;
            }
          }
        }

        & span {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          margin-left: 5px;
          font-family: $inter-font;
        }
      }
    }

    & .tagsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      border-top: 1px solid $grayE4;
      padding-top: 20px;
      padding-bottom: 25px;

      & .tag {
        padding: 7px 13px;
        background: rgba(140, 66, 227, 0.15);
        border-radius: 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        font-family: $inter-font;
        color: $primary;
      }
    }

    & .loader {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
    }
  }
}
