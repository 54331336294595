@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;

  .image {
    width: 110px;
    height: 85px;
    border-radius: 15px;
    overflow: hidden;
    background-color: $grayE4;
  }

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    flex: 1;
  }

  .info {
    &Current {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      font-family: $inter-font;
      color: $gray80;
    }
    &Title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      font-family: $primary-font;
      color: $textblack;
    }
  }

  .progress {
    display: flex;
    column-gap: 14px;

    &Container {
      flex: 1;
    }
    &Line {
      flex: 1;
      background-color: $grayF4;
      margin: 5px 0;
      border-radius: 12px;
      height: 12px;

      &Inner {
        height: 100%;
        background-color: $primary;
        border-radius: 12px;
      }
    }
    &Dates {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      font-family: $inter-font;
      color: $gray80;
    }
    &Count {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      font-family: $inter-font;
      color: $textblack;
    }
  }

  .details {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    align-self: flex-start;

    &Text {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      font-family: $inter-font;
      color: $primary;
    }
    &Arrow {
      color: $primary;
    }
  }
}

.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
}
.modal {
  outline: none;

  & .modalInner {
    width: 900px;
    height: 740px;
    background: $grayF8;
    border-radius: 24px;
    padding: 32px;
    position: relative;
    outline: none;

    & .loader {
      display: flex;
      width: 100%;
      height: 80%;
      align-items: center;
      justify-content: center;
    }

    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    & .closeBtn {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .slider {
    margin: 0 -32px;
  }
  .stats {
    display: flex;
    justify-content: space-between;
  }
  .trackers {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  .updateBtn {
    max-width: 350px;
    margin: 0 auto;
  }
}
