@import "../../../scss/main.scss";

.swrapper {
  max-width: 1820px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  padding: 40px 50px;

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
  }

  & .header {
    display: flex;
    gap: 18px;

    & .goBack {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      margin-right: 30px;

      & svg {
        transform: rotate(180deg);
      }
    }

    & .btn {
      padding: 10px 20px;
      border-radius: 12px;
      border: 2px solid #e4e4e4;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      font-size: 18px;
      cursor: pointer;
    }

    & .activeBtn {
      color: $primary;
      background-color: rgba(140, 66, 227, 0.15);
    }
  }
}

.wrapper {
  width: max-content;
  margin: 30px auto;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.leftContainer {
  max-width: 860px;
  width: 100%;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-top: 10px;
  }
}

.blockItem {
  width: 100%;
  background: $white;
  border-radius: 30px;
  padding: 30px 30px 25px;
  word-wrap: break-word;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  & .shortOverview {
    @include text_large_regular;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  & .boostcampTitle {
    @include desktop_h3;
  }

  & .blockTitle {
    @include desktop_h4;

    @media (max-width) {
      @include mobile_h4;
    }
  }

  & .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & .iconBlock {
    display: flex;
    align-items: center;

    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      background: $primaryOpacity;
      border-radius: 15px;
      margin-right: 15px;

      & svg {
        color: $primary;
      }
    }

    & .iconBlockTitle {
      @include desktop_h5;
      font-size: 16px;
    }

    & .iconBlockSubtitle {
      @include text_x_small_regular;
      color: $gray3A;
    }
  }
}

.rightContainer {
}

.priceBlockWrapper {
  min-width: 407px;
  position: sticky;
  top: 20px;
  padding: 30px;
  border-radius: 30px;
  background: $white;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  max-height: calc(100vh - 40px);

  & .mobileShortDescription {
    border-top: 1px solid #e4e4e4;
    padding-top: 20px;

    & .shortOverview {
      text-align: justify;
    }
    & .flexCollumn {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    }
    & .iconBlock {
      display: flex;
      align-items: center;

      & .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background: $primaryOpacity;
        border-radius: 15px;
        margin-right: 15px;

        @media (max-width: 768px) {
          margin-right: 10px;
        }

        & svg {
          color: $primary;
        }
      }

      & .iconBlockTitle {
        @include desktop_h5;

        @media (max-width: 768px) {
          @include mobile_h5;
        }
      }

      & .iconBlockSubtitle {
        @include text_small_regular;
        color: $gray3A;
      }
    }
  }

  & .mobileTitle {
    @include mobile_h3;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  & .img {
    width: 347px;
    height: 260px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  & .timerWrapper {
    border: 3px dashed #ee3758;
    border-radius: 15px;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      padding: 15px 20px;
      margin-bottom: 15px;
      border-radius: 20px;
    }

    & p {
      @include desktop_h5;
      color: #ee3758;

      @media (max-width: 768px) {
        @include mobile_h5;
      }
    }

    & .counterWrapper {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      & h4 {
        @include desktop_h3;
        color: #ee3758;

        @media (max-width: 768px) {
          @include mobile_h2;
        }
      }
    }
  }

  & .priceContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    margin-bottom: 10px;

    & .selectCountry {
      display: flex;
      align-items: center;
      gap: 10px;
      // border: 1px solid #e4e4e4;
      // padding: 12px 20px;
      border-radius: 15px;
      margin-left: auto;
      // cursor: pointer;
      position: relative;

      @media (max-width: 768px) {
        padding: 12px 14px;
      }

      & span {
        @include text_small_bold;

        @media (max-width: 768px) {
          @include text_x_small_bold;
        }
      }

      & .countryList {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 5px;
        gap: 5px;
        top: 54px;
        left: 0;
        width: 100%;
        background: $white;
        position: absolute;
        border: 1px solid #e4e4e4;
        overflow: hidden;

        & .selectCountry {
          margin-left: 0px;
        }
      }
    }
  }

  & .currentPrice {
    @include desktop_h3;

    @media (max-width: 768px) {
      @include mobile_h2;
    }

    position: relative;

    & .infoBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      position: absolute;
      z-index: 99;
      right: -10px;
      top: -10px;
      background: $white;

      & svg {
        color: $grayAf;
      }

      & .infoBlock {
        padding: 10px 15px;
        @include text_small_regular;
        color: $textdark;
        width: 275px;
        position: absolute;
        background: $white;
        z-index: 99999;
        bottom: 30px;
        left: -120px;
        box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
      }
    }
  }
  & .discoutPrice {
    @include desktop_h4;
    text-decoration-line: line-through;
    color: $grayAf;

    @media (max-width: 768px) {
      @include mobile_h2;
    }
  }

  & .signBtn {
    @include desktop_h4;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 347px;
    height: 76px;
    background: #ee3758;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;

    @media (max-width: 768px) {
      @include text_medium_bold;
      width: 100%;
    }
  }

  & .stripeBlock {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & p {
      @include text_x_small_regular;
      margin-left: 10px;
      flex: 1;
    }
  }

  & .paymentMehods {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}

.readMoreBtnWrapper {
  width: 170px;

  & div {
    @include text_medium_bold;
    font-family: $primary-font;
    font-size: 18px;
  }

  & svg {
    color: $primary;
  }
}

.blur {
  height: 40px;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 90px;
  backdrop-filter: blur(1px);
}

.gradientBlock {
}

.selectMondayBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $grayE4;
  border-radius: 15px;
  padding: 13px 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;
  margin-top: 5px;
  margin-bottom: 20px;

  & svg {
    transform: rotate(180deg);
  }
}

.selectMondayBtnOpen {
  & svg {
    transform: rotate(360deg);
    transition: all 0.2s linear;
  }
}
.mondayPrice {
  @include text_small_bold;
  color: $textblack;
}

.modayListWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  background-color: $white;
  top: 60px;
  left: 0;
  right: 0;
  padding: 10px 10px;
  border: 1px solid $grayE4;
  border-radius: 15px;
  overflow: hidden;

  & .selectMondayBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $grayE4;
    border-radius: 15px;
    padding: 13px 20px;
    cursor: pointer;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  & .selectMondayBtnActive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $primary;
    border-radius: 15px;
    padding: 13px 20px;
    cursor: pointer;
    position: relative;

    & svg {
      color: $primary;
      transform: rotate(0deg);
    }

    & .mondayTitle {
      @include text_small_bold;
      color: $primary;
    }
  }
}

.mondayTitle {
  @include text_small_regular;
  flex: 1;
}

.selectedLabel {
  @include text_small_bold;
}

.opacity {
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  bottom: 100px;
  left: 0;
  box-shadow: 0px -40px 30px rgba(255, 255, 255, 0.85);
}

.checkDaysBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 4px solid $primary;
  gap: 10px;
  padding: 16px;
  border-radius: 30px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 16px;
  }

  & .smallImg {
    width: 61px;
    height: 54px;
    object-fit: cover;
    border-radius: 15px;
  }

  & span {
    @include desktop_h5;
    flex: 1;

    @media (max-width: 768px) {
      @include mobile_h5;
    }
  }
}

.reviewMobileBlock {
  & .blockTitle {
    @include mobile_h4;
    border: none;
  }
}
