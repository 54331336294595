@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 50px;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .createMealBtnWrapper {
      width: 300px;
    }
    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
    }
  }
  & .searchInputWrapper {
    display: flex;
    align-items: center;
    background: #e4e4e455;
    padding: 0px 18px;
    border-radius: 16px;

    & .searchInput {
      flex: 1;
      background: transparent;
      border: none;
      outline: none;
      height: 56px;
      margin-left: 16px;
    }
  }
  & .listLoader {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .listHeader {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 15px 0px;

    &CheckedWrapper {
      display: flex;
      align-items: center;
      flex: 1;
      &Title {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        font-family: $inter-font;
        color: #b2b3bd;
        margin-left: 16px;
      }
    }

    &ShoppingTitle {
      flex: 1;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
    }
    &DateWrapper {
      display: flex;
      align-items: center;
      width: 187px;
    }
    &DateTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
    }
    &Arrow {
      color: $primary;
      margin-left: 3px;
      cursor: pointer;
    }
  }
  & .paginationContainer {
    margin-top: 30px;
    display: flex;
    gap: 8px;

    & .nextBtn {
      cursor: pointer;
      background-color: $black;
      padding: 14px 30px;
      border-radius: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      font-family: $inter-font;
    }
  }
}
.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
}
.modal {
  & .modalInner {
    width: 450px;
    height: 374px;
    background: $white;
    border-radius: 24px;
    padding: 32px;
    position: relative;

    & .name {
      margin-bottom: 10px;
    }

    & .category {
      margin-bottom: 22px;
    }

    & .loader {
      display: flex;
      width: 100%;
      height: 80%;
      align-items: center;
      justify-content: center;
    }

    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    & .closeBtn {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
