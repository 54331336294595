@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 50px;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .createMealBtnWrapper {
      width: 300px;
    }
    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
    }
  }
  & .searchInputWrapper {
    display: flex;
    align-items: center;
    background: #e4e4e455;
    padding: 0px 18px;
    border-radius: 16px;

    & .searchInput {
      flex: 1;
      background: transparent;
      border: none;
      outline: none;
      height: 56px;
      margin-left: 16px;
    }
  }
  & .listHeader {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 15px 0px;

    &IngredientTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: auto;
      width: 230px;
    }

    &CookTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: 25px;
      width: 100px;
    }

    &TagsTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: 25px;
      width: 293px;
    }

    &CheckedWrapper {
      display: flex;
      align-items: center;
      &Title {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        font-family: $inter-font;
        color: #b2b3bd;
        margin-left: 16px;
      }
      &Arrow {
        color: $primary;
        margin-left: 3px;
        cursor: pointer;
      }
    }

    &ShoppingTitle {
      width: 230px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: auto;
    }
  }

  & .list {
    & .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }
  }

  & .paginationContainer {
    margin-top: 30px;
    display: flex;
    gap: 8px;

    & .nextBtn {
      cursor: pointer;
      background-color: $black;
      padding: 14px 30px;
      border-radius: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      font-family: $inter-font;
    }
  }
}
