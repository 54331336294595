@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 50px;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .createMealBtnWrapper {
      width: 300px;
    }
    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
    }
  }
  & .searchContainer {
    display: flex;
    align-items: center;
    gap: 50px;

    position: relative;
    & .filterBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.07);
    }

    & .filterBtnActive {
      background: $primary;
      & svg {
        color: #fff;
      }
    }
    & .filterWrapper {
      width: 300px;
      position: absolute;
      right: 0;
      top: 60px;
      background: #fff;
      padding: 27px 30px;
      border-radius: 25px;
      box-shadow: 0px 18px 30px rgba(0, 0, 0, 0.13);
      z-index: 99;

      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 320px;
      }

      & .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }

      & .selectAllItem {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 15px 0;
        border-bottom: 1px solid $grayE4;
        & span {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          font-family: $inter-font;
        }

        & .selectedAllCheckbox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          border: 2px solid #e4e4e4;
          cursor: pointer;
        }

        & .selecetAllCheckboxActive {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          border: 5px solid $primary;
        }

        & .selectedAllCheckboxFullActive {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          background: $primary;
        }
      }

      & .tagsList {
        overflow-y: scroll;
        max-height: 310px;
        border-bottom: 1px solid $grayE4;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: $grayE4;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: $grayE4;
        }
        & .selectItem {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 13px 0;
          & span {
            font-family: $inter-font;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #808191;
          }
        }

        & .selectItemActive {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 13px 0;
          & span {
            font-family: $inter-font;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #000;
          }
        }
      }

      & .showRecipeBtn {
        height: 44px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary;
        margin-top: 15px;
        cursor: pointer;

        & span {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          font-family: $inter-font;
          color: #fff;
        }
      }

      & .resetBtn {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #808191;
        margin-top: 15px;
        cursor: pointer;
        text-align: center;
        font-family: $inter-font;
      }
    }

    & .searchInputWrapper {
      display: flex;
      align-items: center;
      background: #e4e4e455;
      padding: 0px 18px;
      border-radius: 16px;
      flex: 1;
      & .searchInput {
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        height: 56px;
        margin-left: 16px;
      }
    }
  }

  & .listHeader {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 15px 0px;

    &IngredientTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: auto;
      width: 113px;
    }

    &CookTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: 25px;
      width: 100px;
    }

    &TagsTitle {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: 25px;
      width: 293px;
    }

    &CheckedWrapper {
      display: flex;
      align-items: center;
      &Title {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        font-family: $inter-font;
        color: #b2b3bd;
        margin-left: 16px;
      }
      &Arrow {
        color: $primary;
        margin-left: 3px;
        cursor: pointer;
      }
    }

    &ShoppingTitle {
      width: 230px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      font-family: $inter-font;
      color: #b2b3bd;
      margin-left: auto;
    }
  }

  & .list {
    & .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }
  }

  & .paginationContainer {
    margin-top: 30px;
    display: flex;
    gap: 8px;

    & .nextBtn {
      cursor: pointer;
      background-color: $black;
      padding: 14px 30px;
      border-radius: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      font-family: $inter-font;
    }
  }
}
