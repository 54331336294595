@import "../../../../../scss/main.scss";

.wrapper {
  max-width: 1440px;
}

.btnsContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.greenBtn {
  background: #60c055;
  &:hover {
    background: #60c055;
  }

}
