@import "../../scss/main";

.fasting {
  width: 30px;
  height: 30px;
}

.wrapperLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  @media (max-width: 768px) {
    height: 70vh;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1760px;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }

  & .daysList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .boostcampContent {
    max-width: 946px;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;

    @media (max-width: 768px) {
      max-width: 768px;
      margin-left: 0px;
      margin-right: 0px;
    }

    & .boostcampHeader {
      background: $white;
      border-radius: 30px;
      padding: 30px;

      @media (max-width: 768px) {
        padding: 20px;
        border-radius: 20px;
      }

      & .timerWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 30px;
      }

      & .dayTitle {
        @include desktop_h2;
        margin-bottom: 15px;

        @media (max-width: 768px) {
          @include mobile_h2;
        }
      }

      & .daySubtitle {
        @include text_large_regular;
        color: $gray3A;
        margin-bottom: 30px;

        @media (max-width: 768px) {
          @include text_medium_regular;
          margin-bottom: 15px;
        }
      }

      & .dayPreview {
        width: 100%;
        height: 390px;
        border-radius: 30px;
        overflow: hidden;
        object-fit: cover;

        @media (max-width: 768px) {
          height: 227px;
          border-radius: 20px;
        }
      }
    }

    & .dayOverviewWrapper {
      background: $white;
      border-radius: 30px;
      padding: 30px;
      margin-top: 20px;

      @media (max-width: 768px) {
        padding: 20px;
        border-radius: 20px;
      }

      & .dayOverview {
        @include text_medium_regular;
        color: $gray3A;
        margin-bottom: 30px;
      }

      & .completeDayBtnWrapper {
        position: relative;
        margin: 0 auto;
        width: 347px;
      }
    }
  }

  & .tasksList {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 768px) {
      margin-bottom: -122px;
    }

    & .mealPlanBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 18px 0;
      background-color: $green;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        opacity: 0.9;
      }
      & svg {
        color: $white;
      }

      & p {
        @include text_medium_bold;
        color: $white;
      }
    }

    & .trackersBlurWrapper {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px);
    }

    & .openDayBtn {
      background: $white;
      border: 4px solid $primary;
      border-radius: 30px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 407px;
      cursor: pointer;

      & .dayImg {
        width: 61px;
        height: 54px;
        border-radius: 15px;
        overflow: hidden;
        object-fit: cover;
      }

      & .dayTitle {
        margin-left: 20px;
        @include desktop_h5;
        width: 143px;
        margin-right: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      & .changeDayBtn {
        @include text_small_bold;
        color: $primary;
        margin-right: 10px;
        width: 81px;
        text-align: end;
      }
    }

    & .divider {
      height: 1px;
      background: $grayE4;
      margin-top: 25px;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}
.daysMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 9999999999999;
  margin-top: 70px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;

  & .menuWrapper {
    flex: 1;
  }

  & .menuModal {
    background: $white;

    padding: 30px;
    overflow-y: scroll;
    overflow-x: hidden;

    & .modalHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      & .crossBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      & p {
        margin-top: 3.5px;
        margin-left: 10px;
      }
    }
  }
}

.stickyMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 56px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 40px;
  margin-left: auto;
  margin-right: 16px;
  z-index: 99999;
}
