@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 0;
  border-top: 1px solid #e4e4e4;
}

.avatarBlock {
  word-wrap: break-word;
  width: 120px;
  margin-right: 30px;

  & .avatar {
    width: 120px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
  }
}

.textBlock {
  & .name {
    @include desktop_h5;
  }
  & .city {
    margin-top: 5px;
    @include text_small_regular;
    color: $gray7C;
  }

  & .ratingWrapper {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .text {
    @include text_medium_regular;
    color: $gray3A;
  }
}
