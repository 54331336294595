@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 50px;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & .createMealBtnWrapper {
      width: 300px;
    }
    & .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
    }
  }
  & .searchInputWrapper {
    display: flex;
    align-items: center;
    background: #e4e4e455;
    padding: 0px 18px;
    border-radius: 16px;

    & .searchInput {
      flex: 1;
      background: transparent;
      border: none;
      outline: none;
      height: 56px;
      margin-left: 16px;
    }
  }

  & .loaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  }

  & .tagsList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 8px;
  }
}
.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
}
.modal {
  & .modalInner {
    width: 450px;
    background: $white;
    border-radius: 24px;
    padding: 32px;
    position: relative;
    & .category {
      margin-bottom: 22px;
    }
    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    & .closeBtn {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}

.categoryEditItem {
  border-radius: 8px;
  border: 1px solid $grayAf;
  overflow: hidden;
  // padding-left: 13px;
  padding-right: 3px;
  display: flex;
  align-items: center;

  & .categoryEditItemCancel {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      & path {
        color: $red;
      }
    }
  }

  & .categoryEditItemSave {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      color: $primary;
    }
  }

  & .editInput {
    border: none;
    outline: none;
    height: 30px;
    max-width: 100px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }
}

.categoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px 6px 12px;
  background: $primaryOpacity;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  & span {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: $primary;
  }

  & .menuWrapper {
    width: 109px;
    height: 87px;
    position: absolute;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
    background: #fff;
    z-index: 99;
    top: 34px;
    padding: 10px;

    & .menuButton {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 6px 8px;
      border-radius: 10px;
      transition: all linear 0.2s;

      &:hover {
        background: #f8f8f8;
      }

      & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
        color: #000;
      }
    }
  }
}
