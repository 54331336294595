@import 'scss/main.scss';

.search {
  position: relative;
  width: 280px;
  &__inputInner {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  &__input {
    padding-right: 60px;
    border-radius: 8px;
  }
  button {
    @extend %button;
    @extend %flexCenter;
    width: 48px;
    height: 48px;
    background-color: $blue;
    border-radius: 8px;
    position: absolute;
    @extend %topCenter;
    right: 4px;
  }
}
