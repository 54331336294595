@import "scss/main.scss";

.banDelete {
  &__btn {
    width: auto;
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
    @extend %textMed14;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__btn_active {
    width: auto;
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
    border: 2px solid red !important;
    @extend %textMed14;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
