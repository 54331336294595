@import "../../../../../scss/main.scss";

.wrapper {
  margin-top: 10px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.discountTypeWrapper {
  width: 100%;
  position: relative;
  height: 56px;
  background-color: $grayF8;
  border-radius: 8px;
  padding: 15px 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}

.discountTypeWrapperOpen {
  width: 100%;
  position: relative;
  height: 56px;
  background-color: $grayF8;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  padding: 15px 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}

.discountTypeTitle {
  font-size: 16px;
  caret-color: $blue;
  font-weight: 500;
  line-height: 26px;
}

.discountTypeArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 400ms ease;

  svg {
    transform: rotate(90deg);
  }

  &Open {
    transform: rotateX(180deg);
  }
}

.optionsList {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 56px;
  left: 0;
  z-index: 99;
  background-color: $grayF8;
  border: 1px solid $grayE4;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.optionItem {
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  padding-left: 18px;
  font-weight: 500;
  &:not(:last-child) {
    border-bottom: 1px solid $grayE4;
  }
}

.deleteBtn {
  flex: 0.5;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.communityWrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  & .communityInputContainer {
    flex: 6;
  }
}
