@import "../../scss/main";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  background: $white;
  padding: 30px;
  height: 98px;
  width: 407px;
  gap: 15px;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 20px;
    width: 100%;
  }
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & svg {
  }
}

.btnWrapper {
  width: 100px;
  margin-left: auto;

  .btnValue {
    padding: 15px;
    font-size: 16px;
    line-height: 16px;
    font-family: $inter-font;
    font-style: normal;
    font-weight: 600;
    color: $white;
    margin: 0;

    align-items: center;
    border: 2px solid transparent;
    border-radius: 20px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;

    justify-content: center;
    outline: none;
    width: 100%;
  }
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  position: absolute;
  right: 20px;
  top: 16px;
  background: $white;

  & .editIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-style: dashed;
    border-radius: 100px;
    box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
  }
}

.infoBtn {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  position: absolute;
  left: -5px;
  top: -5px;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  & .infoBlock {
    padding: 10px 15px;
    @include text_small_regular;
    color: $textdark;
    width: 275px;
    position: absolute;
    background: $white;
    z-index: 99999;
    bottom: 28px;
    left: -45px;
    box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  & .infoBlockBottom {
    padding: 10px 15px;
    @include text_small_regular;
    color: $textdark;
    width: 275px;
    position: absolute;
    background: $white;
    z-index: 99999;
    bottom: -50px;
    left: -45px;
    box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
}

.lockedWrapper {
  width: 100px;
  height: 50px;
  border: 2px dashed;
  border-radius: 20px;
  filter: blur(2px);
}

.lockedIconWrapper {
  top: 13px;
  left: 37px;
  position: absolute;
  z-index: 999;
}
