@import "../../../../scss/main.scss";

.overlay {
  @extend %fullFixed;
  @extend %flexCenter;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
  overflow-y: auto;
}

.modal {
  & .modalInner {
    // width: 1126px;
    width: 550px;
    border-radius: 24px;
    background: $white;
    padding: 32px;
    position: relative;

    & .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    & .contentContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 22px;
      gap: 10px;
    }

    & .closeBtn {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .boostcampItem {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .paginationWrapper {
      justify-content: center;
      display: flex;
      margin-top: 12px;
    }
    .loaderWrapper {
      justify-content: center;
      display: flex;
      margin: 20px 0;
    }
  }
}
