@import 'scss/main.scss';

// .log {
//   display: flex;
//   justify-content: space-between;
//   &__showData {
//     display: block;
//   }
//   &__data {
//     display: none;
//   }
// }
.log {
  height: 80px;
  transition: all 0.2s ease;
  &:hover {
    background-color: $grayF8;
  }
  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  td {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 140px;
    @extend %textNormal14;
    &:last-child {
      text-align: right;
    }
  }
  // td:nth-child(2) {
  //   text-align: center;
  // }

  button {
    @extend %button;
    background-color: transparent;
    @extend %textMed14;
    color: $gray80;
    svg {
      width: 18px;
      height: 18px;
      margin-left: 6px;
      margin-bottom: -3px;
    }
  }
}
