@import "../../../scss/main.scss";

.wrapper {
  max-width: 1440px;

  & .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
  }

  & .headerInputWrapper {
    width: 565px;
    margin-top: 30px;

    & .textarea {
      height: 98px;
    }
  }
  & .daysContainer {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 10px;
    border-bottom: 1px solid $grayE4;
  }

  & .selectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grayE4;

    & p {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #808191;
    }

    .selectInput {
      flex: 1;
      max-width: 50%;
    }
  }

  & .addMealBtnWrapper {
    margin-top: 20px;
    width: 365px;

    & .addMealBtn {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 2px dashed #8c42e3;
      border-radius: 16px;
      width: 161px;
      padding: 18px 20px;

      & span {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $primary;
        font-family: $inter-font;
        margin-left: 8px;
      }
    }
  }

  & .launchItem {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grayE4;

    & .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &Title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
      }
      &TitleInput {
        flex: 1;
        max-width: 50%;
      }

      & .mealDeleteBtn {
        margin-right: auto;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: all linear 0.2s;
        cursor: pointer;

        padding: 6px 13px;
        border: 2px solid $red;
        border-radius: 8px;

        &:hover {
          background-color: $grayF8;
        }
      }
    }

    & .contentContainer {
      display: flex;
      align-items: center;
      margin-top: 20px;

      & .addBtnWrapper {
        width: 365px;

        & .addRecipesBtn {
          cursor: pointer;
          display: flex;
          align-items: center;
          border: 2px dashed #8c42e3;
          border-radius: 16px;
          width: 161px;
          padding: 18px 20px;

          & span {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $primary;
            font-family: $inter-font;
            margin-left: 8px;
          }
        }
      }

      & .additionalText {
        flex: 1;
      }
    }
  }

  & .footer {
    display: flex;
    align-items: center;
    padding: 30px 0px;
    border-top: 1px solid $grayE4;

    & .cancelBtn {
      background: $grayE4;
      color: #000;
      width: 166px;
    }

    & .saveBtn {
      width: 166px;
      margin-left: 10px;
    }
    & .createBtn {
      margin-left: auto;
      width: 320px;
    }
  }
}

.sameForWeekBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 13px;
  border: 2px solid $grayE4;
  border-radius: 8px;
  cursor: pointer;

  & span {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: $primary;
  }

  & .arrow {
    transform: rotate(-90deg);
    color: $primary;
  }

  &:active {
    border-color: $primary;
  }
}

.input {
  position: relative;
  & .listLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

  & .listLoadingPagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
  & .dataList {
    position: absolute;
    background-color: $grayF8;
    border-radius: 8px;
    max-height: 230px;
    left: 0;
    right: 0;
    top: 90px;
    overflow-y: scroll;
    z-index: 10;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: $grayE4;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $grayE4;
    }

    & .dataItem {
      padding: 15px 18px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid $grayE4;
      }
    }
  }
  &__main {
    width: 100%;
    height: 56px;
    @extend %input;
    background-color: $grayF8;
    border-radius: 8px;
    padding: 15px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    caret-color: $blue;
    color: #000;
    cursor: pointer;
    border: 1px solid $grayF8;
    display: flex;
    align-items: center;
    position: relative;

    & .arrow {
      transform: rotate(90deg);
      transition: all linear 0.2s;
      width: 12px;
    }

    & .openArrow {
      transform: rotate(270deg);
    }

    & .value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
    }

    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      border-color: $blue;
      border-width: 2px;
    }
  }
  &:not(:last-child) {
    // margin-bottom: 18px;
  }
  h4 {
    @extend %textNormal14;
    color: $placeholder;
    margin-bottom: 6px;
  }
  p {
    position: absolute;
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}
