@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;

    & .optionsBtnContainer {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    & .optionsBtn {
      padding: 0;
      min-width: 50px;
      height: 32px;
      border-radius: 10px;
      padding: 7px 13px;
      box-sizing: border-box;
    }

    & .dates {
      display: flex;
      align-items: center;
      column-gap: 20px;

      &Btn {
        cursor: pointer;
      }
      &Title {
        @include desktop_h4;
        font-weight: 600;
      }
    }
  }

  & .widgetsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    column-gap: 10px;
    width: 100%;

    & .widgetWrapper {
      min-width: 140px;
      flex: 1;
      padding: 20px;
      border-radius: 20px;
      border: 4px solid transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s linear;
      text-align: center;

      & .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        width: 48px;
        height: 48px;
        margin-bottom: 10px;
      }

      & .title {
        @include desktop_h4;
        font-weight: 600;
        text-align: center;
        color: $textblack;
      }

      & .subtitle {
        @include text_small_regular;
        color: $gray3A;
      }
    }

    & .activeWidgetWrapper {
      border-style: solid;
    }
  }

  & .graphContainer {
    width: 100%;
    max-width: 700px;
  }
}
