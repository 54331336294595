@import './vars.scss';

%textNormal14 {
  font-size: 14px;
  line-height: 22px;
}
%textMed14 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
%textReg14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
%textBold24 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
%textNormal16 {
  font-size: 16px;
  line-height: 26px;
}
%textSemi16 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
%textMed16 {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
%textBold32 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
%textSemi20 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

%button {
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
}
%input {
  display: inline-block;
  outline: none;
  border: none;
}
%center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
%topCenter {
  top: 50%;
  transform: translateY(-50%);
}
%flexColumn {
  display: flex;
  flex-direction: column;
}
%alignCenter {
  display: flex;
  align-items: center;
}
%alignCenterSpace {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
%flexSpace {
  display: flex;
  justify-content: space-between;
}
%flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
%full {
  width: 100%;
  height: 100%;
}
%fullPhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
%fullFixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
%shadowRadius {
  background-color: $white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
}
%shadow {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}
%table {
  width: 100%;
  border-collapse: collapse;
}
